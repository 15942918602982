<template>
  <PDialog
    :title="title"
    classes="left-0 md:left-auto md:w-4/5 lg:w-3/5"
    @close="$emit('close')"
  >
    <PDialogContent v-if="loading">
      <PLoader />
    </PDialogContent>

    <PDialogContent v-else>

      <PText
        v-if="update"
        :label="$tk('ExternalLocationDialog.LocationNo', true)">
        {{ number }}
      </PText>

      <PInput
        v-else
        :readonly="update"
        :required="true"
        :label="$tk('ExternalLocationDialog.LocationNo')"
        :error="locationInvalid ? $tk('ExternalLocationDialog.LocationNumberAlreadyExists', true) : ''"
        v-model="number"
      />

      <PInput
        v-model="name"
        :required="true"
        :label="$tk('ExternalLocationDialog.LocationName')"
        class="mt-4"
      />

      <PInput
        v-model="contactName"
        :required="true"
        :label="$tk('ExternalLocationDialog.ContactName')"
        class="mt-4"
      />

      <PInput
        v-model="street"
        :required="true"
        :label="$tk('Common.Address.Street')"
        class="mt-4"
      />

      <div class="flex flex-wrap">

        <PInput
          v-model="zip"
          :required="true"
          :label="$tk('Common.Address.PostCode')"
          class="w-1/2 pr-2 mt-4"
        />

        <PInput
          v-model="city"
          :required="true"
          :label="$tk('Common.Address.PostPlace')"
          class="w-1/2 pl-2 mt-4"
        />

      </div>

      <PSelect
        class="w-full mt-4"
        :required="true"
        :label="$tk('ExternalLocationDialog.Country')"
        v-model="country"
        :items="countries"
        itemValue="id"
        itemText="name"
      />

    </PDialogContent>
    <PDialogActions
      v-if="!loading"
      :split="canDelete"
    >
      <PButton
        v-if="canDelete"
        :disabled="deleting"
        color="secondary"
        icon="trash-alt"
        id="delete"
        @click="deleteLocation"
      >
        <span v-html="deleteText"></span>
      </PButton>

      <div class="flex space-x-2">

        <PButton
          color="secondary"
          @click="$emit('close')"
          :disabled="saving"
        >
          <PTranslation k="Common.Actions.Cancel" />
        </PButton>

        <PButton
          @click="save"
          :disabled="!valid"
          :loading="saving"
        >
          <span v-html="submitText"></span>
        </PButton>
      </div>

    </PDialogActions>
  </PDialog>
</template>

<script>
  import api from "../../api"
  import http from "@/utilities/http"
  import { mapGetters } from "vuex"
  import { find } from "lodash"

  export default {
    name: "ExternalLocationDialog",
    data() {
      return {
        saving: false,
        deleting: false,
        loading: true,
        number: "",
        name: "",
        contactName: "",
        street: "",
        zip: "",
        city: "",
        country: "NOR",
        chep: "",
        countries: [],
        transactionCount: 0
      }
    },
    props: {
      existingIds: {
        type: Array,
        default: () => { return [] }
      },
      recId: {
        type: String,
        default: ""
      }
    },
    computed: {
      ...mapGetters(["customer", "location"]),
      submitText () {
        return this.saving
        ? this.$tk("Common.Actions.Saving")
        : this.$tk("Common.Actions.Save")
      },
      deleteText () {
        return this.deleting
        ?  this.$tk("Common.Actions.Deleting")
        :  this.$tk("Common.Actions.Delete")
      },
      valid () {
        return  this.locationInvalid === false &&
                this.number !== "" &&
                this.name !== "" &&
                this.contactName !== "" &&
                this.street !== "" &&
                this.zip !== "" &&
                this.city !== ""
      },
      locationInvalid () {
        if (this.update) {
          return false
        } else {
          return find(this.existingIds, id => { return id === this.number }) !== undefined
        }
      },
      update () {
        return this.recId === '' ? false : true
      },
      title () {
        return this.update
        ? this.$tk("ExternalLocationDialog.UpdateExternalLocation")
        : this.$tk("ExternalLocationDialog.CreateExternalLocation")
      },
      canDelete () {
        return this.recId !== "" && this.transactionCount === 0
      }
    },
    methods: {
      async save () {

        this.saving = true

        try {

          await http.post("ExternalLocation", {
              name: this.name,
              street: this.street,
              postalCode: this.zip,
              postalName: this.city,
              countryRegionId: this.country,
              customerId: this.customer.id,
              locationId: this.location.id,
              externalLocationId: this.number,
              contactName: this.contactName,
              customerStatus: 1 })

          this.$emit("updated")
          this.$emit("close")

        } catch (e) {

          this.$notifyError(
            this.$tk("ExternalLocationDialog.ErrorSavingLocation", true),
            this.$formatApiError(e)
          )

        } finally {
          this.saving = false
        }
      },

      async loadLocation (recId) {
        let location = await http.get("ExternalLocation", { params: { id: recId }})
        this.number = location.externalLocationId
        this.name = location.name
        this.street = location.street
        this.zip = location.postalCode
        this.city = location.postalName
        this.contactName = location.contactName
        this.transactionCount = location.transactionCount
      },

      async deleteLocation () {

        this.deleting = true

        try {

          await api.externalLocationDelete(this.recId)

          this.$notifySuccess(this.$tk("ExternalLocationDialog.LocationDeleted", true))

          this.$emit("updated")
          this.$emit("close")

        } catch (e) {

          this.$notifyError(
            this.$tk("ExternalLocationDialog.ErrorDeletingLocation", true),
            this.$formatApiError(e)
          )

        }

        this.deleting = false
      }
    },
    async created () {
      this.countries = await api.AddressCountryRegions()
      if (this.update) {
        await this.loadLocation(this.recId)
      }
      this.loading = false
    }
  }
</script>